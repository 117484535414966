
import LogoText from "component/Logo"
import { Card, Typography ,Box} from '@mui/material';
import FormateCurrency from 'utils/FormateCurrency100k';
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material';
import FormatToIntCurrency from 'utils/FormatToIntCurrency';
import LogoImg from 'assets/xgainTM.png'
import XLogo from 'assets/X.svg';
import { Form } from 'react-router-dom';
import {GaugeChart as NewGaugeChart} from './new-gauge-chart';

const GaugeChart=({
    total_invested,
    total_roi,
    total_worth,
    profit_loss,
    xgain,
    label='Main ',
    grand_total_sell,
    trim,
})=>{
    const theme=useTheme()
    const isMobile=useMediaQuery(theme.breakpoints.down('xl'))
    return(
        <Card  elevation={0} sx={{bgcolor:'primary.light',boxShadow:'0px 0px 2px #fff',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',padding:{xs:2,sm:0.2,md:2},height:'100%'}}>
        {/* <Typography  sx={{marginBottom:1,color:'#fff',fontWeight:'bold'}} textTransform='capitalize'>{label}</Typography> */}
        {/* <Box sx={{background:profit_loss<0?'#e51616':'#52B12C'}} className="gauge">
          <div style={{top:xgain>=100?-15:xgain==0?0:'auto',transform:`rotate(${xgain>100?180:1.8*parseFloat(xgain)}deg)`}} className='needle-parent'>
          <div class="needle" ></div>
          </div>
          <Box className="gauge-center">
          </Box>
          <div class='info-area'>
          <Typography  sx={{textAlign:'center',marginTop:-3, marginBottom:1,color:'#fff',fontWeight:'bold',textTransform:'capitalize'}}>{label}</Typography>
             <Typography sx={{
                textAlign:'center',
                fontWeight:'bold',
                
                }}>
              </Typography>
              <Typography
               sx={{
               textAlign:'center',
               fontSize:{xs:'4.5rem',sm:'3rem',md:'3.5rem',lg:'4rem',xl:'6rem'},
               fontWeight:'bold',
               position:'absolute',
               bottom:0,
               width:'100%',
           
            }}
              >{xgain}
              <Box component={'img'} sx={{height:{xs:'3rem',sm:'1.4rem',md:'2rem',lg:'2.6rem',xl:'3.8rem'}}} src={XLogo}/>
              </Typography>
          </div>
        </Box> */}
        <NewGaugeChart 
        currentXGain={total_worth/total_invested}
        maxXGain={grand_total_sell/total_invested}
        />
        <Box sx={{
            width:'100%',
            px:{xs:1,sm:0},
            display:'flex',
            justifyContent:'space-around',
            paddingTop:{xs:1,sm:2},
            color:'#fff',
            fontWeight:'bold'
        }}>
            <Box sx={{textAlign:'center'}}>
               <Typography sx={{fontWeight:'bold',fontSize:{xs:12,sm:13,md:14,lg:16,xl:16}}}>Invested</Typography>  
               <Typography sx={{fontSize:{xs:14,sm:13,md:14,lg:16,xl:16}}}>{!isMobile?FormateCurrency(total_invested):FormatToIntCurrency(total_invested)}</Typography>  
            </Box>
            <Box sx={{textAlign:'center'}}>
               <Typography sx={{fontWeight:'bold',fontSize:{xs:12,sm:13,md:14,lg:16,xl:16}}}>Worth</Typography>  
               <Typography sx={{fontSize:{xs:14,sm:13,md:14,lg:16,xl:16}}}>{!isMobile?FormateCurrency(total_worth):FormatToIntCurrency(total_worth)}</Typography>  
            </Box>
            <Box sx={{textAlign:'center'}}>
               <Typography sx={{fontWeight:'bold',fontSize:{xs:12,sm:13,md:14,lg:16,xl:16}}}>P&L</Typography>  
               <Typography sx={{fontWeight:'bold',color:profit_loss<0?'red':'#95F205',fontSize:{xs:14,sm:13,md:14,lg:16,xl:16}}}>{!isMobile?FormateCurrency(profit_loss):FormatToIntCurrency(profit_loss)}</Typography>  
            </Box>
           
           {trim?<Box sx={{textAlign:'center'}}>
               <Typography sx={{fontWeight:'bold',fontSize:{xs:12,sm:13,md:14,lg:16,xl:16}}}>Trim</Typography>  
               <Typography sx={{fontWeight:'bold',color:profit_loss<0?'red':'#95F205',fontSize:{xs:14,sm:13,md:14,lg:16,xl:16}}}>{!isMobile?FormateCurrency(trim):FormatToIntCurrency(trim)}</Typography>  
            </Box>:null}
            <Box sx={{textAlign:'center'}}>
               <Typography  sx={{fontWeight:'bold',fontSize:{xs:12,sm:13,md:14,lg:16,xl:16}}}>pROI</Typography>
               <Typography sx={{fontSize:{xs:14,sm:13,md:14,lg:16,xl:16}}}>{!isMobile?FormateCurrency(grand_total_sell):FormatToIntCurrency(grand_total_sell)}</Typography>
            </Box>
        </Box>
        </Card>
    )
}

export default GaugeChart;