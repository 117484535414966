import { useCallback, useEffect, useMemo, useState } from "react";
import FormaeteIntCurrency from "utils/FormatToIntCurrency";
import formateCurrentcy from "utils/FormateCurrency";
import formateQTY from "utils/FormateQty";
import EditCrypto from "component/Modal/EditCrypto";
import Delta from 'assets/delta.png'
import { Add, ArrowDownward, ArrowUpward, Edit, ExpandMore, Delete as Trash, Close, Remove } from "@mui/icons-material";
import React from "react";
import {
    Table, TableBody, TableHead, TableRow, TableCell, TextField, Typography, Button, Menu, MenuItem,
    Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TableContainer, Paper, Box,
    Badge,
    Tooltip
} from "@mui/material"
import AddMoreCrypto from "component/Modal/AddMoreCrypto";
import { deleteCrypto } from "api/crypto";
import { useDispatch } from "react-redux";
import { set_message } from "redux/reducer/message";
import LogoText from "component/Logo";
import CustomInputDark from "component/Input/CustomInputDarkText";
import FibModal from "component/Modal/FibModal";
import ExchangeandWallet from "component/ExchangeWallet";
import columndata from "data/columndata";
import TableColumnModal from "component/Modal/TableColumn/TableColumn";
import {Settings} from "@mui/icons-material"
import axios from "axios";
import SellCrypto from "component/Modal/SellCrypto";
import ExchangeIcon from "component/Exchanges/ExcangeIcon";
const Td = ({ id, value, name, symbol, edit = true, color, bold }) => {
    const [focus, setFocus] = useState(false);
    const [text, setText] = useState(value)
    const toggle = (click = true) => {
        if (!edit) return;
        setFocus(!focus)
    }
    return (
        <TableCell sx={{ bgcolor: color, height: '10px', textAlign: 'center' }} onClick={toggle}>
            <Typography style={{ color: '#fff', fontWeight: bold && 'bold' }}>{symbol}  {text}</Typography>
        </TableCell>
    )
}

const TdImg = ({ id, data, setModalData }) => {
    const ErrorCount = () => useMemo(() => {
        let error = 0;
        if (!data.ATL) {
            error++;
        }
        if (!data.ATH) {
            error++
        }
        if (data.ATH < data.ATL) {
            error++
        }
        return error;
    }, [data])

    const ErrorText = () => useMemo(() => {
        let errorText = [];
        if (!data.ATL) {
            errorText.push("ATL is missing! Enter manually")
        }
        if (!data.ATH) {
            errorText.push("ATH is missing! Enter manually")
        }
        if (data.ATH < data.ATL) {
            errorText.push("ATH can't be lower than ATL")
        }
        return errorText.join(',');
    }, [data])

    const showModal = () => {
        setModalData(data)
    }
    // return (<TableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //     <Button onClick={showModal} style={{ textDecoration: 'underline', color: '#fff', textAlign: 'center', display: 'block', mx: 'auto', p: 1 }} >{name?.replaceAll('_', '')}</Button>
    // </TableCell>
    // )

    return (
        <TableCell sx={{ zIndex: 0,cursor:'pointer' }} className="sticky-col-category">
            <Box onClick={showModal} sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',height:'40px',}}>
                    <Tooltip style={{ display: 'block', margin: 'auto' , }} placement="top" hidden={!ErrorCount()} arrow title={ErrorCount() ? <h2>{ErrorText()}</h2> : ''}>
                        <Badge sx={{
                            "& .MuiBadge-badge": {
                                color: "#fff",
                                backgroundColor: "red",
                                right: 2,

                            },
                            bgcolor: 'none'
                        }} badgeContent={ErrorCount()}>

                            <img style={{marginTop:2}} height={28}  width={28} src={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/64/${id?.toLowerCase()}.png`} />
                        </Badge>
                    </Tooltip>
                </Box>
                <Box  sx={{ textAlign:'left',ml:1.2 }}>
                    <Typography style={{textDecoration: 'none',color: '#fff', fontWeight:'500',display:'block', fontSize: 14, marginTop: 1, textOverflow:'ellipsis',whiteSpace:"nowrap",width:60,overflow:'hidden' }} >{data.symbol?.replaceAll('_', '')}</Typography>
                    <Typography style={{marginTop:-1, color:'#fff',fontSize: 10, textOverflow:'ellipsis',whiteSpace:"nowrap",width:60,overflow:'hidden' }} >{data.name}</Typography>
                </Box>
            </Box>
        </TableCell>
    )
}

// DELETE DATA FROM TABLE 
const DeleteCrpto = ({ id, symbol, refresh, handleClose }) => {
    const [showConfirm, setShowConfirm] = useState(true);
    const dispatch = useDispatch();
    const deletedata = () => {
        deleteCrypto(id)
            .then(() => {
                dispatch(set_message('crytpo deleted sucessfully'));
                refresh();
                toggle();
            })
            .catch((err) => { dispatch(set_message('something went wrong')) })
    }

    const toggle = () => {
        setShowConfirm(!showConfirm)
    }

    useEffect(() => {
        if (showConfirm == false) {
            handleClose && handleClose();
        }
    }, [showConfirm])

    return (
        <>
            <Dialog
                open={showConfirm}
                onClose={toggle}
                
                PaperProps={{
                    sx:{
                        background: 'primary.light',
                        p: 1.8,
                        borderRadius: '10px',
                        border: '2px solid #000',
                        boxShadow: 24, 
                    },
                    style: {
                        background: '#293143',
                        border: '2px solid #000',
                        boxShadow: 24,
                        maxWidth: 410,
                        width:'90%',
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-title">
                    <img src={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/64/${symbol.toLowerCase()}.png`} /><br />
                    <p style={{color:"#fff"}}>  Delete all  {symbol}? </p>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText color={'#fff'} textAlign={'center'} id="alert-dialog-description">
                        This action cannot be reversed. {symbol}.
                    </DialogContentText>
                </DialogContent>
                <Box sx={{ width: '100%', height: 1.1, background: '#fff', marginTop: 3 }} />
                <DialogActions sx={{paddingTop:2}}>
                    <Button sx={{ color: '#fff',textTransform:'capitalize' }} onClick={toggle}>Cancel</Button>
                    <Button sx={{ color: '#fff', borderColor: "#fff", borderRadius: '20px',textTransform:'capitalize' }} variant="outlined" onClick={deletedata} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}




// ADD MORE COINS TO EXISTING ONE  





const Action = React.memo(({ id, symbol, setAddMoreData, setEditData, setDeleteData,setSell }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const addExchange=()=>{
        let element=document.querySelector(`#exchange-${id}`)
        if(element){
            element.click()
            setAnchorEl(false);
        }
    }
    return (
        <TableCell>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <ExpandMore sx={{ color: '#fff', fontSize: 30 }} />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() =>{setSell({ id, symbol })}}>
                    <Button startIcon={<Remove />}>
                        Sell
                    </Button>
                </MenuItem>
                <MenuItem onClick={() =>{setAnchorEl(null);setAddMoreData({ id, symbol })}}>
                    <Button startIcon={<Add />}>
                        Add More
                    </Button>
                </MenuItem>
                <MenuItem onClick={addExchange}>
                    <Button startIcon={<Add />}>
                        Exchanges
                    </Button>
                </MenuItem>
                <MenuItem onClick={() => {setAnchorEl(false); setEditData({ id, symbol }) }}>
                    <Button startIcon={<Edit color="#000" />}>
                        Update 
                    </Button>
                </MenuItem>
                <MenuItem  onClick={() => {setAnchorEl(false);setDeleteData({ id, symbol })}}>
                    <Button startIcon={<Trash />} sx={{ color: "red" }}>
                        Delete
                    </Button>
                </MenuItem>
            </Menu>
        </TableCell>
    )
})



const Walletes = ({ data }) => {
    return (
        <TableCell>
            <Box sx={{ display: 'flex',width:'max-content' }}>
                <ExchangeandWallet id={data._id} wallet={JSON.parse(data.wallet||'[]')}/>
            </Box>
        </TableCell>
    )
}
const Row = ({columns, data, refresh, setAddMoreData, setDeleteData, setEditData, setModalData,setSell }) => {
    return (<TableRow sx={{ height: 'auto' }} style={{ height: '10px' }} id={data._id} key={data._id}>
        {columns.rank &&<Td value={data.cmc_rank} edit={false} name='cmc_rank' id={data._id} />}
        {columns.symbol && <TdImg  setModalData={setModalData} id={data.symbol} data={data} />}
        {/* <CryptoLink data={data} setModalData={setModalData} name={data.symbol} /> */}
        {columns.qty && <Td value={formateQTY(data.qty)} name='qty' id={data._id} />}
        {columns.price &&<Td colo value={formateCurrentcy(data.price)} color={data.price > (data.avg_buy) ? '#BB4444' : '#248822'} bold edit={false} name='price' id={data._id} />}
        {columns.avg_buy &&<Td value={formateCurrentcy(data.avg_buy)} name='avg_buy' id={data._id} />}
        {columns.invested &&<Td value={formateCurrentcy(data.invested)} name='invested' id={data._id} />}
        {columns.xgain && <Td value={<span style={{ fontFamily: 'Roboto' }}>{(data.cXGAIN).toFixed(1)}<b style={{ color: 'red' }}>X</b> </span>} edit={false} id={data._id} />}
        {columns.worth && <Td value={formateCurrentcy(data.price * data.qty)} edit={false} id={data._id} />}
        {columns.p_and_l &&<Td value={formateCurrentcy((data.price * data.qty) - (data.invested))} color={(data.price * data.qty) - (data.invested) < 0 ? '#BB4444' : '#248822'} bold edit={false} name='p_and_l' id={data._id} />}
        {/* <Td value={formateCurrentcy(data.OG_ATH || data.ATH)} bold edit={false} name='p_and_l' id={data._id} />
        <Td value={formateCurrentcy(data.OG_ATL || data.ATL)} bold edit={false} name='p_and_l' id={data._id} /> */}
        {columns.pXgain &&<Td value={<span style={{ fontFamily: 'Roboto' }}>{(data.XGAIN).toFixed(0)}<b style={{ color: 'red' }}>X</b> </span>} edit={false} id={data._id} />}
        {columns.pRoI &&<Td value={formateCurrentcy(data.grand_total_sell)} bold edit={false} name='grand_total_sell' id={data._id} />}
        {columns.xATH && <Td value={<span style={{ fontFamily: 'Roboto' }}>{(parseFloat(data.ATH / data.price)).toFixed(1)}<b className="x">X</b> </span>} edit={false} id={data._id} />}
        {columns.deltaATH && <Td value={data?.drop ? data?.drop?.toFixed(1) + '%' : "NA"} color={data.drop < 100 ? '#BB4444' : '#248822'} bold edit={false} name='drop' id={data._id} />}
        {columns.deltaHr && <Td value={data?.percent_change_1h ? data?.percent_change_1h?.toFixed(1) + '%' : 'NA'} color={data.percent_change_1h < 0 ? '#BB4444' : '#248822'} bold edit={false} name='drop' id={data._id} />}
        {columns.deltaDay &&<Td value={data?.percent_change_24h ? data?.percent_change_24h?.toFixed(1) + '%' : 'NA'} color={data.percent_change_24h < 0 ? '#BB4444' : '#248822'} bold edit={false} name='drop' id={data._id} />}
        {columns.deltaWeek && <Td value={data?.percent_change_7d ? data?.percent_change_7d.toFixed(1) + '%' : "NA"} color={data.percent_change_7d < 0 ? '#BB4444' : '#248822'} bold edit={false} name='drop' id={data._id} />}
        {columns.deltaMonth && <Td value={data?.percent_change_30d ? data?.percent_change_30d.toFixed(1) + '%' : "NA"} color={data.percent_change_30d < 0 ? '#BB4444' : '#248822'} bold edit={false} name='drop' id={data._id} />}
        {columns.deltaYear && <Td value={data?.percent_change_year ? data?.percent_change_year.toFixed(1) + '%' : "NA"} color={data.percent_change_year < 0 ? '#BB4444' : '#248822'} bold edit={false} name='drop' id={data._id} />}
        {columns.Hr24Vol && <Td value={data.volume ? FormaeteIntCurrency(data.volume) : "NA"} bold edit={false} name='volume' id={data._id} />}
        {columns.mktCap && <Td value={data.market_cap ? FormaeteIntCurrency(data.market_cap) : "NA"} bold edit={false} name='drop' id={data._id} />}
        {columns.trade && <Walletes data={data}/>}
        {columns.action && <Action refresh={refresh} setEditData={setEditData} setDeleteData={setDeleteData} setAddMoreData={setAddMoreData} setSell={setSell} id={data._id} symbol={data.symbol} />}
    </TableRow>)
}



const CategoryTable = ({ data, refresh }) => {
    let [tableData, setTablData] = useState([...data?.map(obj => ({
        ...obj, 'XGAIN': (obj.grand_total_sell / obj.invested),
        'p_and_l': (obj.price * obj.qty) - (obj.invested),
        'cXGAIN':(obj.price/obj.avg_buy)
    }))]);
    const [sortType, setSortType] = useState({});
    const [editData, setEditData] = useState(null);
    const [deleteData, setDeleteData] = useState(null);
    const [addMoreData, setAddMoreData] = useState(null);
    const [sell,setSell]=useState(null);
    const [query, setQuery] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [showColumnModal, setShowColumnModal] = useState(false);
    const [columns,setColumns] = useState(columndata);
    const [currentExchanges,setCurrentExchanges]=useState("");

    useEffect(()=>{
        fetchPreference();
    },[])

    useEffect(() => {
        if (tableData.length) return;
        setTablData([...data?.map(obj => ({
            ...obj, 'XGAIN': (obj.grand_total_sell / obj.invested),
            'XATH': obj.ATH / obj.price, total_value: obj.qty * obj.price,
            'p_and_l': (obj.price * obj.qty) - (obj.invested),
            'cXGAIN':(obj.price/obj.avg_buy)
        }))])
    }, [data])

    useEffect(() => {
        let temp = [...data?.map(obj => ({
            ...obj, 'XGAIN': (obj.grand_total_sell / obj.invested),
            'XATH': obj.ATH / obj.price, total_value: obj.qty * obj.price,
            'p_and_l': (obj.price * obj.qty) - (obj.invested),
            'cXGAIN':(obj.price/obj.avg_buy)
        }))];
        let key = sortType.selected;
        // if(sortType.selected){
        if (sortType[key] == 1) {
            temp.sort(function (a, b) {
                return parseFloat(a[key]) - parseFloat(b[key]);
            });
        }
        else if (sortType[key] == 2) {
            temp.sort(function (a, b) {
                return parseFloat(b[key]) - parseFloat(a[key]);
            });
        } else if (!sortType[key]) {
            setTablData([...data?.map(obj => ({
                ...obj,
                'XGAIN': (obj.grand_total_sell / obj.invested), 'XATH': obj.ATH / obj.price, total_value: obj.qty * obj.price,
                'p_and_l': (obj.price * obj.qty) - (obj.invested),
                'cXGAIN':(obj.price/obj.avg_buy)
            }))]);
            return;
        }
        setTablData([...temp])
        // }
    }, [data])

    const fetchPreference=async()=>{
        try{
            let res=await axios.get('/api/v1/user/column-preference');
            setColumns(res.data)
        }catch(e){
            console.log(e)
        }
    }
    const rowsData = useMemo(() => {
        let rows=tableData;
        if (query) rows= tableData.filter(obj => (obj.symbol.includes(query)));
        if(currentExchanges){
            if(currentExchanges=="Trust"){
                rows=rows.filter((obj)=>{
                    let wallet=JSON.parse(obj.wallet||'[]');
                    return wallet.includes('Trust')
                })
            }else{
                rows=rows.filter(obj=>obj.wallet?.includes(currentExchanges))
            }
           
        }
        return rows;
    }, [tableData, query,currentExchanges])

    const sortData = useCallback((key) => {
        if (sortType[key] == undefined || sortType[key] == 3) {
            tableData.sort(function (a, b) {
                return parseFloat(a[key]) - parseFloat(b[key]);
            });
            setSortType({ selected: key, [key]: 1 })
        }
        else if (sortType[key] == 1) {
            tableData.sort(function (a, b) {
                return parseFloat(b[key]) - parseFloat(a[key]);
            });
            setSortType({ selected: key, [key]: 2 })
        } else if (sortType[key] == 2) {
            setTablData([...data?.map(obj => ({
                ...obj,
                'XGAIN': (obj.grand_total_sell / obj.invested), 'XATH': obj.ATH / obj.price, total_value: obj.qty * obj.price,
                'p_and_l': (obj.price * obj.qty) - (obj.invested),
                'cXGAIN':(obj.price/obj.avg_buy)
            }))]);
            setSortType({})
            return;
        }
        setTablData([...tableData])
    }, [tableData, sortType, setTablData, setSortType])


    const updateColumns=async(preference)=>{
        try{
            axios.patch('/api/v1/user/column-preference',preference);
            setColumns(preference)
        }catch(e){
            console.log(e)
        }
        setShowColumnModal(false);
    }

    const getIcon = (key) => {
        // return <ArrowDownward  sx={{color:'green',position:'absolute',marginLeft:'-25px'}} color="green" />
        if (key == sortType.selected) {
            if (sortType[key] == 1) {
                return <ArrowUpward sx={{ color: '#fff', position: 'absolute', marginLeft: '-25px' }} />
            } else if (sortType[key] == 2) {
                return <ArrowDownward sx={{ color: '#fff', position: 'absolute', marginLeft: '-25px' }} />
            }
        }

    }

    return (
        <Box sx={{maxHeight:'95vh',position:'sticky',top:'10px'}}>
            {addMoreData && <AddMoreCrypto refresh={refresh} visible={true} setVisble={setAddMoreData} id={addMoreData.id} symbol={addMoreData.symbol} handleClose={() => setAddMoreData(null)} />}
            {editData && <EditCrypto handleClose={() => setEditData(null)} visible={true} setVisble={setEditData} refresh={refresh} id={editData.id} symbol={editData.symbol} />}
            {deleteData && <DeleteCrpto id={deleteData.id} symbol={deleteData.symbol} handleClose={() => setDeleteData(null)} refresh={refresh} />}
            {sell && <SellCrypto handleClose={() => setSell(null)} visible={true} setVisble={setSell} refresh={refresh} id={sell.id} symbol={sell.symbol} />}
            {modalData && <FibModal setModalData={setModalData} {...modalData} />}
            {showColumnModal && <TableColumnModal column={columns}  setColums={updateColumns} />}
            <Box className="category-table" sx={{display:'flex',justifyContent:"right"}}>
                <Box sx={{w:'150px'}}>
                    <Button onClick={()=>{setCurrentExchanges('')}} style={{w:5,h:5,display:'inline-grid',placeContent:'center'}} sx={{m:0,minWidth:0,mx:'2px',bgcolor:''==currentExchanges?"primary.light":''}}>
                     <Typography sx={{color:"#fff",fontWeight:'bold',mr:1,fontSize:15}}>All</Typography> 
                    </Button>
                 {['Coinbase','Binance','KuCoin','MexC', 'Kraken', 'OKX','ByBit','BitMart', 'Bitget','iTrust','Ledger','Trust'].map((name)=>{
                   return(
                      <Button onClick={()=>{setCurrentExchanges(name)}} style={{w:5,h:5,display:'inline-grid',placeContent:'center'}} sx={{m:0,minWidth:0,mx:'2px',bgcolor:name==currentExchanges?"primary.light":''}}>
                     <ExchangeIcon  containerMargin={0} containerHeight={25} size={25} isLink={false}  name={name}/>
                     </Button>
                   )
                 })}
               </Box>
            </Box>
            <TableContainer className="category-table" sx={{ maxHeight: '90vh', position: 'sticky', margin: { lg: '10px auto' }, marginBottom: '10px' }}>
                <Table stickyHeader sx={{ bgcolor: 'primary.light', }} class="main-table table  table-hover">
                    <TableHead sx={{ zIndex: 2, bgcolor: 'primary.light', }}>
                        <TableRow sx={{ bgcolor: 'primary.light', }} className="category-table-row">
                           {columns.rank &&<TableCell sx={{ bgcolor: 'primary.light', cursor: 'pointer' }} scope="col" className="cutome-head" onClick={() => { sortData('cmc_rank') }}>{getIcon('cmc_rank')} </TableCell>} {/* Rank Api fetch*/}
                            {columns.symbol && <TableCell sx={{ bgcolor: 'primary.light', px: 3, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }}>
                                <CustomInputDark type={'text'} endAdornment={query && <Button onClick={() => setQuery('')} sx={{ margin: 0, padding: 0, minWidth: '5px' }}><Close sx={{ color: "#fff", fontSize: 18 }} /></Button>} value={query} onChange={(e) => setQuery(e.target.value.toUpperCase())} label='Search' sx={{ width: 80 }} />
                            </TableCell>} {/* Detail Link */}
                            {columns.qty &&<TableCell sx={{ bgcolor: 'primary.light', px: 3, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }}>Qty</TableCell>}  {/*  */}
                            {columns.price && <TableCell sx={{ bgcolor: 'primary.light', px: 5, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => { sortData('price') }} className="cutome-head">{getIcon('price')} Price</TableCell>}
                            {columns.avg_buy && <TableCell sx={{ bgcolor: 'primary.light', px: 5, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }}>AveBuy</TableCell>}
                            {columns.invested &&<TableCell sx={{ bgcolor: 'primary.light', px: 3, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => { sortData('invested') }} className="cutome-head">{getIcon('invested')}Invested</TableCell>} {/* Qty x Ave Buy Price */}
                            {columns.xgain &&<TableCell sx={{ bgcolor: 'primary.light', px: 2.5, textAlign: 'center', py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => { sortData('cXGAIN') }} className="cutome-head" scope="col">{getIcon('cXGAIN')}<LogoText fontSizeTm={6} /></TableCell>}
                            {columns.worth &&<TableCell sx={{ bgcolor: 'primary.light', px: 3, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => { sortData('total_value') }} className="cutome-head">{getIcon('total_value')}Worth</TableCell>} {/* Qty x Current Price */}
                            {columns.p_and_l && <TableCell sx={{ bgcolor: 'primary.light', px: 3, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => { sortData('p_and_l') }} className="cutome-head">{getIcon('p_and_l')}P&L</TableCell>} {/* Tot Value - Total Invested */}
                            {/* <TableCell sx={{ bgcolor: 'primary.light', px: 3, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => { sortData('OG_ATH') }} className="cutome-head">{getIcon('p_and_l')}ATH</TableCell> Tot Value - Total Invested */}
                            {/* <TableCell sx={{ bgcolor: 'primary.light', px: 3, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => { sortData('OG_ATL') }} className="cutome-head">{getIcon('p_and_l')}ATL</TableCell> Tot Value - Total Invested */}
                            {columns.pXgain && <TableCell sx={{ bgcolor: 'primary.light', px: 2.5, textAlign: 'center', py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => { sortData('XGAIN') }} className="cutome-head" scope="col">{getIcon('XGAIN')}p<LogoText fontSizeTm={6} /></TableCell>}
                            {columns.pRoI && <TableCell sx={{ bgcolor: 'primary.light', px: 3, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => { sortData('grand_total_sell') }} className="cutome-head">{getIcon('grand_total_sell')}pROI</TableCell>} {/* Tot Value - Total Invested */}
                            {columns.xATH && <TableCell sx={{ bgcolor: 'primary.light', px: 1, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} className="cutome-head" onClick={() => { sortData('XATH') }}>{getIcon('XATH')}<span className="x">X</span>ATH</TableCell>}
                            {columns.deltaATH && <TableCell sx={{ bgcolor: 'primary.light', px: 1, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} className="cutome-head" onClick={() => { sortData('drop') }}>{getIcon('drop')} <img src={Delta} />ATH</TableCell>}
                            {columns.deltaHr &&<TableCell sx={{ bgcolor: 'primary.light', px: 1, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} className="cutome-head" onClick={() => sortData('percent_change_1h')}>{getIcon('percent_change_1h')}  <img src={Delta} />Hr</TableCell>}
                            {columns.deltaDay &&<TableCell sx={{ bgcolor: 'primary.light', px: 1, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} className="cutome-head" onClick={() => sortData('percent_change_24h')}>{getIcon('percent_change_24h')}  <img src={Delta} />Dy</TableCell>}
                            {columns.deltaWeek &&<TableCell sx={{ bgcolor: 'primary.light', px: 1, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} className="cutome-head" onClick={() => sortData('percent_change_7d')}>{getIcon('percent_change_7d')}  <img src={Delta} />Wk</TableCell>}
                            {columns.deltaMonth &&<TableCell sx={{ bgcolor: 'primary.light', px: 1, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} className="cutome-head" onClick={() => sortData('percent_change_30d')}>{getIcon('percent_change_30d')}  <img src={Delta} />Mo</TableCell>}
                            {columns.deltaYear &&<TableCell sx={{ bgcolor: 'primary.light', px: 1, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} className="cutome-head" onClick={() => sortData('percent_change_year')}>{getIcon('percent_change_year')}  <img src={Delta} />Yr</TableCell>}
                            {columns.Hr24Vol &&<TableCell sx={{ bgcolor: 'primary.light', px: 1, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} className="cutome-head" onClick={() => sortData('volume')}>{getIcon('volume')}  24hrVol</TableCell>}
                            {columns.mktCap &&<TableCell sx={{ bgcolor: 'primary.light', px: 1, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} className="cutome-head" onClick={() => sortData('market_cap')}>{getIcon('market_cap')}  MktCap</TableCell>}
                            {columns.trade &&<TableCell sx={{ bgcolor: 'primary.light', py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer', width: 'max-content' }} >Trade</TableCell>}  {/* Add More coin column */}
                            {columns.action && <TableCell sx={{ bgcolor: 'primary.light', px: 1, py: 1.2, whiteSpace: 'nowrap', cursor: 'pointer' }} >
                                <Button onClick={()=>setShowColumnModal(true)}>
                                    <Settings  sx={{color:"#fff"}}/>
                                </Button>
                                </TableCell>}  {/* Add More coin column */}
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {rowsData && rowsData.map(element => <Row
                            setAddMoreData={setAddMoreData}
                            setDeleteData={setDeleteData}
                            setEditData={setEditData}
                            setModalData={setModalData}
                            setSell={setSell}
                            columns={columns}
                            key={`${element.price}-${element.updatedAt}-${showColumnModal}`} refresh={refresh} data={element} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default CategoryTable;


