import { getToken } from "firebase/messaging";
import { messaging } from "../firebaseConfig";
export const requestPermission = async () => {
    try {
        await Notification.requestPermission();
        const currentToken = await getToken(messaging, { vapidKey:"BKICOb4N9NNXG1S-kvYpVjHHcQLXunQ1CIS7392Lj2jhM25IuHv28ypCBndKlgSYcdaD_GIrLIyHQpw50NtHhG0"});
        if (currentToken) {
            fetch('/api/v1/user/notification-token', {
                method: 'put',
                body: JSON.stringify({ token: currentToken }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } else {
            console.error('No token available. Make sure notifications are enabled.');
        }
    } catch (error) {
        console.error('Error generating token:', error);
    }
};

