// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getMessaging,onMessage} from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBGAcMsRG2IbeEVmgGiuDNkNjz4CsihXhM",
  authDomain: "xgain-44f90.firebaseapp.com",
  projectId: "xgain-44f90",
  storageBucket: "xgain-44f90.appspot.com",
  messagingSenderId: "750604493402",
  appId: "1:750604493402:web:67cdb5139ad611f4462e63",
  measurementId: "G-7FXDKF6R21"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const messaging = getMessaging(app);


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });